import { useDispatch, useSelector } from "react-redux"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { useRouter } from "next/router"
import Image from "next/image"

import Logo from "assets/urbio_login_logo.svg"
import { getCookie } from "helpers/cookie"
import { loginUser } from "redux/actions"

import { LoginForm } from "components/core/Forms"
import styles from "styles/pages/login.module.scss"

export default function Login() {
  const loginPending = useSelector((state) => state.userReducer.loginPending)
  const dispatch = useDispatch()
  const router = useRouter()

  const handleLogin = (values) => {
    dispatch(loginUser(values, (lng) => router.push("/", "/", { locale: lng })))
  }

  return (
    <div className="container">
      <div className={styles.panel}>
        <Image className={styles.logo} src={Logo} alt="Urbio Logo" />
        <LoginForm onSubmit={handleLogin} submitPending={loginPending} />
      </div>
    </div>
  )
}

export const getServerSideProps = async (ctx) => {
  const token = getCookie("token", ctx.req)

  if (token) {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    }
  }

  return {
    props: { ...(await serverSideTranslations(ctx.locale, ["common"])) },
  }
}
